<template>
  <div ref="book" id="book" :class="'on-page-' + page">
  <div class="book">
    <div class="page">
        <div class="page-content page-cover">
          <div class="page-cover-header">
            <h1>Hodně budeš<br />někde v Asii!</h1>
            <h2>aneb hledám parťáka na nomádění<br />v Indii, Japonsku a na Bali</h2>

            <img src="../assets/arrow-right.png" style="height: 40px; width: 40px; margin-top: 20px;" v-if="!mobile" />
            <img src="../assets/arrow-right.png" style="height: 40px; width: 40px; margin-top: 20px; -webkit-transform:rotate(90deg); -moz-transform: rotate(90deg); -ms-transform: rotate(90deg); -o-transform: rotate(90deg); transform: rotate(90deg);" v-if="mobile" />
            <!--
            <img src="../assets/swipeleft.png" style="height: 40px; width: 40px; margin-top: 20px;" v-else @click="nextPage" />
            -->
          </div>
        </div>
    </div>
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Ahoj světoběžníku! 🙋‍♂️</h2>
            </div>
            <div class="page-text">
                <p>
                  Díky za návštěvu! Jolanda mi jednou vyvěštila můj osud slovy „Hodně budeš někde“ a ta prostorově nevýrazná paní měla pravdu! 😂
                  Tentokrát bych chtěl hodně být pár měsíců ztracenej v Asii. 🌏
                  Už jsem se v Asii na rok ztratil, ale nestihnul jsem očuchat Indii, tak to potřebuju napravit!
                </p>
                
                <p>
                  Už nechci letět sám a tak touhle velice seriózní cestou hledám někoho prdlýho 💩 do týmu! 
                  Určitě na ten nejlepší cestovatelskej zážitek, kterej si dokážeš představit! 🤩
                  Jestli máš podobný sen, dej tomuhle plánu šanci, udělej si pohodlí, hoď starosti za hlavu a oko 👀 na tenhle plán.
                </p>

                <img src="https://www.t-shock.eu/obrazek-nahled/AQKoLC5Fp6Kh3cN2.png" />
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" v-if="mobile" />
            </div>
        </div>
    </div>
    
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Plán cesty</h2>
            </div>
            <div class="page-text">
                <p>
                  Vše je o domluvě. Takže „Vidím, slyším poslouchám!“ 👂
                  Jen potřebuji většinou ve všední dny cca 4-6h pracovat. A sháním k sobě někoho, kdo to má stejně - někoho kdo si sbalí 2 švestky a notebook a huráá pracovat odkudkoliv na světě. 🌏
                  Časový posun v tomhle případě hraje v náš prospěch.
                  Chtěl bych vyrazit v průběhu října a na cestách strávit cca 2 až 6 měsíců. 😇
                </p>

                <p>
                  V bucket listu mám tentokrát tydle země:
                </p>
                <ol>
                  <li>Měsíc v Indii 🇮🇳</li>
                  <li>2 týdny v Japonsku 🇯🇵</li>
                  <li>Zbytek relax na Bali 🇮🇩</li>
                  <li>... další nápady? 🤷</li>
                </ol>
            </div>
            <div class="paging paging-right">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>
    
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Indie</h2>
            </div>
            <div class="page-text">
                <p>
                  Tady vidím velký špatný! 😅<br />
                  Možná se ptáš, proč zrovna Indie? No protože... tam vlastně nic nefunguje. 😅
                  Budeme se muset spolehnout jenom sami na sebe. 😎
                  A buď ji budeme nesnášet nebo milovat. Jak to teda bude?
                  Zážitek nemusí být pozitivní, hlavně když je silný! 😃
                </p>

                <img src="https://www.t-shock.eu/obrazek-nahled/hhWVDfY8PIOa6GSh.png" />

                <ul>
                  <li><strong>Čas:</strong> časový posun je +3,5h a od 29.10.2023 to bude +4,5h. Díky posunu máme vždycky minimálně dopoledne a pak večer na objevování! 🤩</li>
                  <li><strong>Víza:</strong> 30denní za 25 USD. Rovněž funguje jako double–entry. <a href="https://indianvisaonline.gov.in/evisa/tvoa.html" target="_blank">Online zde.</a></li>
                  <li><strong>Měna:</strong> 100RUP = 26Kč</li>
                  <li><strong>Letenky:</strong> nejlevnější jsem našel z Vídně na kiwi.com za cca 4-5000 Kč s odbavenou batožinou.</li>
                </ul>

                <img src="../assets/indie.webp" />
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage"  v-if="mobile"/>
            </div>
        </div>
    </div>
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>New Delhi</h2>
            </div>
            <div class="page-text">
                <p>
                  Sem přiletíme, bude tu teplo, hrozně rušno a šílenej vzduch. To chceš, panebože! 😅
                </p>

                <p>
                  Na místo ubytování máme v zásadě dvě možnosti buď Paharganj (takové pravá backpackerská oblast, trošku nebezpečnější) nebo South Delhi.
                  Nechci vybírat konkrétní lokaci a ubytování, to bych chtěl vybrat společně. Ale dá se sehnat od cca 100Kč/den, pokud budeme chtít něco hodně levného. Za 500Kč/noc si už můžeme žít jako králové. 👑
                  Můžeme tu být jak dlouho budeme chtít. Ale myslím, že za 3-4 dny už nám to poleze krkem a budeme utíkat jinam.
                </p>

                <h3>Místa na práci</h3>
                <p>Našel jsem skvělej článek <a href="https://digest.myhq.in/cafes-to-sit-and-work-in-delhi/" target="_blank">17 Cafes to Work in Delhi</a> takže je z čeho vybírat. Nejradši bych pracoval z <strong>Dr. Zombie</strong> 😅</p>
                <img src="https://digest.myhq.in/wp-content/uploads/2019/03/Dr.Zombie-1024x536.jpg" />
            </div>
            <div class="paging paging-right">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile"/>
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>
    <div class="page" >
        <div class="page-content">
            <div class="page-header">
                <h2>New Delhi</h2>
            </div>
            <div class="page-text">
                <p>Pár míst mě zaujalo a chtěl bych je oběhnout. Předpokládám, že budou vypadat úplně stejně jako na fotkách! 😅</p>
                
                <h3>Red fort</h3>
                <img src="https://cdn.britannica.com/75/152475-050-DA89FF0A/Red-Fort-Old-Delhi-India.jpg" />
                
                <h3>Lotusový chrám</h3>
                <img src="https://cdn.puzzlegarage.com/img/puzzle/1a/1914_preview_r.v3.webp" />
                
                <h3>Agrasen Ki Baoli</h3>
                <img src="https://res.cloudinary.com/purnesh/image/upload/w_1000,f_auto,q_auto:eco,c_limit/92876190-2638504226396955-8630912871820957302-n-1.jpg" />
                
                <h3>Bhardwaj Lake</h3>
                <img src="https://media2.thrillophilia.com/images/photos/000/237/544/original/1587565727_shutterstock_1052240531.jpg?w=753&h=450&dpr=1.5" />
                
                <h3>Deer Park</h3>
                <img src="https://imgmediagumlet.lbb.in/media/2019/11/5ddbcaccdbe3c433711855a6_1574685388150.JPG?fm=webp&w=750&h=500&dpr=2" />
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" v-if="mobile"  />
            </div>
        </div>
    </div>
    <div class="page" >
        <div class="page-content">
            <div class="page-header">
                <h2>Džajpur</h2>
            </div>
            <div class="page-text">
                <p>
                  Sem se z Delhi dostaneme nočním vlakem, který jede cca 5h.
                  Džajpuru se přezdívá růžové město.
                  Tady bych rád strávil pár dní a pak se vydal do Agry.
                  Na Džajpur mám doporučení od jednoho fajn inda, kterýho jsem potkal při toulkách po Florencii.
                </p>

                <h3>Jal Mahal</h3>
                <p>aneb malý teaser na Taj Mahal 😂</p>
                <img src="https://www.jaipurstuff.com/wp-content/uploads/2019/03/jal-mahal-jaipur.jpg" />

                <h3>Místo na práci</h3>
                <p>Našel jsem super kavárnu <a href="https://haydenrue.com/india/rajasthan/town-coffee-in-jaipur/" target="_blank">Town Coffee in Jaipur</a>, odkud se nám bude skvěle pracovat.</p>
                <img src="https://haydenrue.com/wp-content/uploads/2023/06/Town-Coffee-in-Jaipur_Perfect-Cafe-for-Digital-Nomads.jpg" />
            </div>
            <div class="paging paging-right">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>
    <div class="page" >
        <div class="page-content">
            <div class="page-header">
                <h2>Agra</h2>
            </div>
            <div class="page-text">
                <p>... aneb odškrtnutí divu světa z bucket listu. Z Džajpuru je to 4h cesty vlakem. Opět v Agře jsou i další věci k vidění, pojďme to naplánovat společně! Bude to skvělá zastávka po cestě do z Džajpuru do Varanási.</p>

                <img src="https://viaggidelgenio.it/wp-content/uploads/2022/07/taj-mahal-1000x565.jpg" />
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" v-if="mobile"  />
            </div>
        </div>
    </div>
    <div class="page" >
        <div class="page-content">
            <div class="page-header">
                <h2>Varanásí</h2>
            </div>
            <div class="page-text">
                <p>
                  Aneb cesta za pálením mrtvolek na řece Ganga.
                  Tady Indický smrádek určitě nabyde další rozměr! 😃
                  Z Agry se sem dá zase dostat vlakem, tentokrát cesta bude asi trošku výzva cca na 13h, ale slyšel jsem, že cestování vlakem po Indii je jeden z největších zážitků! 😅
                </p>
                
                <img src="../assets/indatrain.gif" />

                <p>
                  V tomhle městě je zakázáno jíst a prodávat maso, takže otrava masem s breberkama tady nehrozí! 😇
                  Je tu spoustu míst kde si zkusit jógu za západu slunce! Půjdem do toho?
                  Chtěl bych tady být cca 2-4 dny.
                </p>

                <img src="https://images.lonelyplanetitalia.it/uploads/gettyrf-827065008?q=80&p=slider&s=5595667a6e1e401506daa05413bd405b" />
            </div>
            <div class="paging paging-right">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>
    <div class="page" >
        <div class="page-content">
            <div class="page-header">
                <h2>Varanásí - Večerní rituál světla </h2>
            </div>
            <div class="page-text">
                <p>
                  Je rituál na uctívání boha Gangy, koná se každý den podél řeky Ganga, nejlepší místo kde ho sledovat je Dashashwamedh Ghat.
                  Je to jeden z nejhezčích hinduistických rituálu, to chci zažít!
                </p>

                <img src="https://media2.thrillophilia.com/images/photos/000/097/362/original/1527581093_Varanasi_Ganga_Aarti.jpg?w=753&h=450&dpr=1.5" />
                
                <p>
                  Bohužel se ale obávám, že Vlastíkovo štěněněněněněně to bude překonávat jen těžko! 🥲
                </p>
                
                <div class="video-container video-jolanda" id="vid-RYn3V9YPMb4">
                  <iframe width="560" height="315" id="player-RYn3V9YPMb4" src="https://www.youtube.com/embed/RYn3V9YPMb4?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" v-if="mobile" />
            </div>
        </div>
    </div>
    <div class="page" >
        <div class="page-content">
            <div class="page-header">
                <h2>Kolkata</h2>
            </div>
            <div class="page-text">
                <p>
                  Je to jedno z největších a nejvýznamnějších měst v Indii.
                  Byla by to poslední zastávka před Goa. Časově bych tu pobyl zas 3-4 dny.
                  Dostaname se sem opět vlakem, cca za 13h, ale třeba po předchozí zkušenosti s vlakem to bude potřeba naplánovat jinak! 😊
                </p>

                <img src="https://www.fabhotels.com/blog/wp-content/uploads/2019/05/Victoria-Memorial_1400.jpg" />

                <h3>Odkud pracovat?</h3>
                <p>
                  Opět jsem našel super <a href="https://www.slurrp.com/article/the-10-best-writer-work-friendly-cafes-in-kolkata-1675850504652" target="_blank">článek</a>.
                  Nejradší bych pracoval z <strong>Pancham Er Adday</strong> což je místo zasvěcené nějakýmu Bollywood filmovýmu hudebnímu mágovi. 😅
                  A vypadá to tam fakt cool!
                </p>
                <img src="https://images.slurrp.com/prodrich_article/mregeraawca.webp?impolicy=slurrp-20210601&width=880&height=500">
            </div>
            <div class="paging paging-right">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>
    <div class="page" >
        <div class="page-content">
            <div class="page-header">
                <h2>Goa</h2>
            </div>
            <div class="page-text">
                <p>
                  A kde nejlíp zakončit tenhle silnej indickej zážitek? Jedině na těch nejhezčích plážích v Goa! 🤩 Letěl bych sem letadlem, je to cca na 5-6h.
                </p>
                
                <p>
                  Tady bych chtěl bydlet v chatce přímo na pláži.
                  Je to taky super místo na serfování! 🏄‍♂️ 🌊
                  Pobyl bych tu dokud nás to bude bavit! 😎
                </p>

                <img src="https://images.lonelyplanetitalia.it/static/places/goa-96.jpg?q=90&p=2400%7C1350%7Cmax&s=bd1ab67c0bbf19c220963da7e3aae641" />
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" v-if="mobile" />
            </div>
        </div>
    </div>
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Japonsko</h2>
            </div>
            <div class="page-text">
                <p>
                  „Země vycházejícího slunce“ aneb cesta za ochutnávkou toho nejluxusnějšího hovězí Waygu na světě!
                  Kde to může být lepší než přímo u zdroje! 😋
                </p>
                <p>
                  Tady se budeme asi taky mačkat v davech Asijců, ale když se ztratíme rychle se najdem, protože já se svojí vejškou budu vždycky čouhat! 🤙 
                  Bude tu asi trochu draho a asi tu bude všechno fungovat, prostě ideální země na vstřebání zážitků z Indie! 😅
                </p>

                <ul>
                  <li><strong>Čas:</strong> časový posun je +7h a od 29.10.2023 to bude +8h, takže můžeme objevovat celý den. Pracovat se bude až večer.</li>
                  <li><strong>Víza:</strong> netřeba, na 90 dnů jsou visa on arrival pro čechy</li>
                  <li><strong>Měna:</strong> 1 000YEN = 155Kč</li>
                  <li><strong>Letenky:</strong> nejlevnější jsem našel z New Delhi na kiwi.com za cca 4-5000 Kč s odbavovačkou.</li>
                </ul>

                <img src="../assets/japonsko.jpg" />
            </div>
            <div class="paging paging-right">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Tokyo</h2>
            </div>
            <div class="page-text">
                <p>
                  Tokyo je jedno z nejmodernějších měst, hafo mrakodrapů a bláznivejch Japončíků.
                  Tak na 3 dny. 🙂
                  Jolanda tvrdila „Pro mě je málo tisic korun“, tak pro Japončíky je to taky málo i za hostel.
                  Na bydlení v hotelech to moc nevidím, ale tipuju, že hostel bude stejný jako ⭐️⭐️⭐️⭐️ hotel v Čechách 😂.
                </p>

                <div class="video-container video-jolanda" id="vid-o1Is49zY8Nw">
                  <iframe width="560" height="315" id="player-o1Is49zY8Nw" src="https://www.youtube.com/embed/o1Is49zY8Nw?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <img src="https://cdn.cheapoguides.com/wp-content/uploads/sites/2/2020/05/akihabara-iStock-484915982-1280x600.jpg" />

                <h3>Výlet na úpatí hory Fuji</h3>
                <p>Vlakem 1h10m. Bohužel nahoru se dá pouze do 10. Září. Jinak je cesta těžce schůdná kvůli povětrnostním podmínkám + všechny chaty po cestě jsou zavřené. V listopadu zdolatelná jen s mačkama a cepínem, výzva? 😅</p>

                <img src="https://cdn.britannica.com/03/75503-050-F65891FA/volcanic-cone-Japan-Mount-Fuji.jpg" />
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" v-if="mobile" />
            </div>
        </div>
    </div>
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Kjóto</h2>
            </div>
            <div class="page-text">
                <p>
                  Bývalé hlavní město Japonska.
                  Je to tradiční město, s krásnýma dřevěnejma chrámama a zahradama.
                  Taky tak na 3 dny.
                </p>

                <img src="https://media-cdn.sygictraveldata.com/media/800x600/612664395a40232133447d33247d383735343934" />

                <h3>Geisha everywhere</h3>
                <p>
                  Tady určitě stojí za to se jít podívat na tradiční Geisha představení!
                </p>
                <img src="https://i.imgflip.com/3bxkz7.jpg" />

                <h3>Nara: Setkání s jeleny v Nara Parku a čumendo na sochu Velkého Buddhy v Todai-ji chrámu</h3>
                <p>Do tohodle národního parku bych se chtěl podívat 😍</p>

                <img src="https://cdn.cheapoguides.com/wp-content/uploads/sites/3/2017/10/Deer-and-Gate-1280x600.jpg" />
            </div>
            <div class="paging paging-right">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Hirošima</h2>
            </div>
            <div class="page-text">
                <p>
                  Město s památníkem míru a hlavně kousek od králičího ostrova!
                  Tady to bude zas na cca 3dny.
                  A pak huráá na Bali!
                </p>

                <img src="https://1gr.cz/fotky/idnes/13/021/org/TOM48f031_hirosima_3.jpg" />

                <h3>Výlet na Okunoshima: Ostrov králíků 🐰</h3>
                <p>
                  Ostrov dříve sloužil jako místo pro továrnu chemických zbraní.
                  Kvůli testování zbraní sem kdysi přivezli pár králíků, kteří se tady rozmnožili a teď je jich ostrov plný.
                  Vonět jako Azurit asi nebudou, ale třeba budou večer trošku svítit! Rozhodně si je chci pomačkat! 😅 Spoustu antibakteriálního gelu se sebou!
                </p>

                <div class="video-container" id="vid-RdeX4NqvDZw">
                  <iframe width="560" height="315" id="player-RdeX4NqvDZw" src="https://www.youtube.com/embed/RdeX4NqvDZw?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" v-if="mobile" />
            </div>
        </div>
    </div>
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Bali</h2>
            </div>
            <div class="page-text">
                <p>
                  Indii bude asi potřeba dlouho rozdýchávat, kde to může bejt lepší než na Bali?
                  A lepšího průvodce po Bali budeš těžko hledat! Žil jsem tady skoro půl roku... 😎
                  Tady už si budeme jenom užívat sluníčka, pláží, můžeme se učit serfovat nebo potápět ... a „Do toho robota“, ale z pláže!
                </p>

                <div class="video-container video-jolanda" id="vid-ykBhdtiiNjE">
                  <iframe width="560" height="315" id="player-ykBhdtiiNjE" src="https://www.youtube.com/embed/ykBhdtiiNjE?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <p>
                  Chtěl bych tady najít vilku s bazénem 🤩.
                  Vím jak to tady chodí, mám tu známé, které můžu případně požádat o pomoc s hledáním ubytka.
                  Můžeme tu být 1, 2, 3 měsíce, jak dlouho jen budeme chtít!
                </p>

                <ul>
                  <li><strong>Čas:</strong> časový posun je +6h a od 29.10.2023 to bude +7h, takže pokud chceme s prací začínat jakoby v 9h ráno, máme skoro celej den na Bali!</li>
                  <li><strong>Víza:</strong> Po příletu zaplatit za Visa on arrival, která jsou na 30 dní a dají se 1x prodloužit. Po vypršení víz, je možnost udělat visa run - odletět ze země třeba do Kuala Lumpur nebo do Singapore a zase se vrátit.</li>
                  <li><strong>Měna:</strong> 100 000IDR = 144Kč</li>
                  <li><strong>Letenky:</strong> nejlevnější jsem našel z Tokya na kiwi.com za cca 4-5000 Kč s odbavovačkou.</li>
                </ul>

                <img src="../assets/bali-uvod.jpg" />
            </div>
            <div class="paging paging-right">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>
    <!--
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Místa na Bali</h2>
            </div>
            <div class="page-text">
                <img src="../assets/bali-mapa.jpg">

                <h3>💚 Canggu</h3>

                <p>
                  Nejlepší místo na Bali, kde mít základnu. Je tady všechno blízko - pláž, spoustu možností kam jít na jídlo, kulturní vyžití, serf školy.
                  Je tu maličko rušněji, ale daj se najít klidný zákoutí.
                  Hlavně třeba v okolí Warrungu Yess - Jl. Pantai Pererenan.
                  Jediný místo na Bali kde jsou taky coworkingy, odkud se dá v pohodě pracovat spolu s dalšími nomády.
                </p>

                <img src="https://www.liveandinvestoverseas.com/wp-content/uploads/2020/09/canggu-bali-indonesia-min.jpg" />

                <h3>❌ Kuta</h3>
                <p>Fucking turistická oblast na kraji hlavního města Denpasar, tomuhle místu se chceš vyhnout obloukem. Je tu velká kriminalita, ceny hrozně vysoko nastavené pro turisty.</p>

                <h3>😝 Sanur</h3>
                <p>Část hlavního města Denpasaru hned vedle letiště na Bali. Je tu spoustu lidí a nic moc pláže.</p>

                <h3>😝 Jimbaran a Bukit</h3>
                <p>
                  “Poloostrov” pod letištěm v Denpasaru. Stojí za to se sem podívat, docela klidná oblast, s pár hezkejma plážema, nejhezčí je Melasti Beach. 
                  Když chceš odsud vyrazit někam po Bali, musíš se na skútru prodrat celým Denpasarem a to není sranda.
                </p>

                <h3>Ubud</h3>
                <p>
                  Takové spirituální město na sever od Denpasaru. Výlet cca na 2-3 dny. Je tu spoustu turistů a dost věcí k vidění:
                </p>

                <ul>
                  <li>Rýžové terasy</li>
                  <li>Monkey forest</li>
                  <li>Zajít na představení Kečak dance</li>
                  <li>Trhy</li>
                  <li>Jóga</li>
                  <li>Spoustu chrámů -> v jednom z nich se dá koupat v posvátné vodě 🙂</li>
                  <li>Ochutnávka kafe luwak -> cibetková káva</li>
                  <li>Po cestě do ubudu je Bali bird park - zoo s papouškama</li>
                </ul>

                <h3>Joshua District</h3>
                <p>
                  Cool místo vlastněné čechy, je tu ubytování a česká restaurace. O víkendech se vaří klasická česká jídla. Z restaurace je výhled na rýžová pole. Na nový rok organizují uklid pláže. Super místo kam zajít o volné sobotě nebo neděli na oběd.
                </p>
                
                <h3>Výlet na Tanah Lot</h3>
                <p>
                  Sestava chrámů který jsou zajímavý minimálně tím, že se k nim dá dostat jen při odlivu. Po přílivu jsou cesty k nim zatopené a chrámy nejsou dostupné. Výlet na pár hodin z Canggu.
                </p>
                
                <h3>Amed</h3>
                <p>
                  Severo východní část ostrova. Velice klidná rybářská oblast. Je možný vyrazit v 5h ráno na loďce s rybářem na lov ryb při východu slunce. Dobrý místo na víkendový výlet.
                </p>
                
                <h3>Severní část ostrova - výlet na vodopády</h3>
                <p>
                  Severní část ostrova je hodně klidná, ale nic moc tu není. Jsou tu super vodopády a pár chrámů a zahrad, které stojí za to vidět.
                </p>

                <ul>
                  <li>Sekumpul</li>
                  <li>Gitgit</li>
                  <li>Aling Aling</li>
                </ul>

                <h3>Výlet na východ slunce na Batur</h3>
                <p>
                  Neaktivní sopka na Bali na kterou se dá vyjít. Vychází se brzo ráno před východem slunce s guidem, který nese snídani, kterou si dáš při východu slunce nahoře.
                </p>

                <h3>Nusa Lembongan a Nusa Penida</h3>
                <p>
                  Nejbližší ostrov u Bali. Je tu klid, dá se sem dostat fast boatem z Denpasaru. Je tady super potápění s mantami. Na Lembonganu jsou super mangrovy - stromy co rostou ve slané vodě. Skvělý na víkendovej výlet.
                </p>

                <h3>Lombok a Gili islands</h3>
                <p>
                  Další ostrov za Nusa Penida. Je tu sopka s jezerem v kráteru Mt. Rinjani, na kterou bych chtěl vylézt. Je to tuším hike na celý den. Gili islands jsou takové 3 maličké ostrovy na severo západní části ostrova, je super tam strávit aspoň jednu noc. Celý výlet na lombok je tak na 3-4 dny. Dá se sem dostat trajektem z Denpasaru na trajekt se dá vzít vlastní motorka. Jsou tu také super vodopády - Tiu Kelep.
                </p>
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" v-if="mobile" />
            </div>
        </div>
    </div>
    -->
    <div class="page" >
        <div class="page-content">
            <div class="page-header">
                <h2>Kam dál?</h2>
            </div>
            <div class="page-text">
                <p>
                  Když budeme chtít, můžeme si říct, že se nám ještě nechce domů a stát se na chvíli Kiwííky (tak se přezdívá Novozélanďanům) nebo Ozííky (tak se přezdívá Australanům) 🤩
                  Koupit si dodávku a project s ní Zéland je můj další sen!
                </p>

                <img src="https://cdn.kimkim.com/files/a/content_articles/featured_photos/3117192df163b1c655a6081e58961533bd8e1afe/big-317a87546e8649aae22e694d77523453.jpg" />
            </div>
            <div class="paging paging-right">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>

<!--
    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Málem bych zapomněl na Harryho! Aneb zaklínadla 🪄</h2>
            </div>
            <div class="page-text">
                <p>
                  Všechny díly Harryho jsem neviděl a ani nečetl 😇, ale za to vím, že cestování ve dvou může být někdy pěkně náročný. 😱
                  Přišlo mi dobrý si vzít na pomoc Harryho a jeho kouzelná zaklínadla, která nás budou po cestě chránit a zajišťovat hranice a bezpečí.
                  Můžeme je použít kdykoliv a může je použít kdokoliv z nás. Proti kouzlům neexistují protikouzla! 😅
                </p>

                <h3>1. Deletrius ⚡️</h3>
                <p>
                  Po vyslovení kouzla se druhý člen týmu dočasně ztratí a nechá toho kdo zaklínadlo vyslovil o samotě.
                  Kouzlo působí na zbytek dne kdy bylo vysloveno.
                </p>

                <h3>2. Finite incantatem ⚡️⚡️</h3>
                <p>
                  Středně silné kouzlo, jeho vyslovení znamená hledání letenek a co možná nejrychlejší přesun do jiné lokace nebo opuštění země.
                </p>
                <div class="video-container" id="vid-u7rUn_JauFo">
                  <iframe width="560" height="315" id="player-u7rUn_JauFo" src="https://www.youtube.com/embed/u7rUn_JauFo?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <h3>3. Avada Kedavra ⚡️⚡️⚡️</h3>
                <p>
                  Nejsilnější kouzlo, po jeho vyslovení končí společné cestování a naše cesty se rozdělují. Kouzlo začne působit hned následující den.
                </p>
                <div class="video-container" id="vid-Iooyl5WxPqA">
                  <iframe width="560" height="315" id="player-Iooyl5WxPqA" src="https://www.youtube.com/embed/Iooyl5WxPqA?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <h3>Kouzelné večeře 🪄</h3>
                <p>
                  Maličko se zajímám a o psychologii a seberozvoj, takže další prdlou věc co bych si rád nastavil je denní check-in.
                  Každý den bych se chtěl pobavit o tom jak se cítíme, co potřebujeme změnit, dělat jinak, abychom předešli případným sporůůům 😱 a ponorkám!
                  Můžeme se tím bavit každý den při večeři a jde mi o to, si vzájemně říct:
                </p>
                <ol>
                  <li>Jak se cítím?</li>
                  <li>Dnešek jsem si užil, protože ...</li>
                  <li>Dnešek mě vyčerpával, protože ...</li>
                  <li>Potřebuju ...</li>
                </ol>
            </div>
        </div>
    </div>
-->
    

    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Koho hledám a kdo jsem já?</h2>
            </div>
            <div class="page-text">
                <ol>
                  <li>Hledám parťáka nebo parťačku do pohody a nepohody!</li>
                  <li>Někoho kdo už je nebo se chce stát digitálním nomádem a jeho práce mu to umožňuje 👩‍💻</li>
                  <li>Někoho kdo je dost šílenej a prdlej 🤪 Protože tendle plán je šíleně prdlej!</li>
                  <li>Někoho kdo má rád ostré jídlo! 🤣🌶</li>
                  <li>Někoho na koho se můžu spolehnout, protože ve dvou se to lépe Indií táhne! 🇮🇳</li>
                  <li>Já jsem fér, spolehlivej, upřímnej, komunikativní a nikdy tě nenechám v bryndě!</li>
                  <li>Občas budu sarkastickej a taky někdy řeknu fakt blbej vtip!</li>
                  <li>V pohodě se domluvím AJ</li>
                  <li>Umím dobře řídit motorku v téhle Asijské džungli! 🛵 😅</li>
                </ol>

                <!--
                <p>
                  Nabízí se otázka, jestli touhle cestou hledám partnerku do života? Odpověd je jasné ano i ne 😅.
                  "Nejsem ňákej fanatik" a vím, že se těch věcí pro vztah musí sejít spoustu, tak pro začátek buďme po cestě dobrými partáky a když se z toho vyvine něco víc, uvidíme jestli tomu dáme šanci. 🤗
                  Můžes se spolehnout, že nehledám kamarádku s výhodami! 🤮
                </p>

                <div class="video-container video-jolanda" id="vid-yCa5urpd77A">
                  <iframe width="560" height="315" id="player-yCa5urpd77A" src="https://www.youtube.com/embed/yCa5urpd77A?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                -->
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" v-if="mobile" />
            </div>
        </div>
    </div>

    <div class="page">
        <div class="page-content">
            <div class="page-header">
                <h2>Můj příběh ...</h2>
            </div>
            <div class="page-text">
                <p>
                  ...ehm, díky, za dočtení až sem. Tak teď je řada na mně. 😇
                </p>
                <p>
                  Když jsem byl malej, měl jsem skvělý a bezstarostný dětství. 😅 Moji rodiče měli hypotéku na krku, žádnej čas, málo peněz a hodně tvrdou ruku. Skvělá kombinace! 🤩
                  Na mně se to podepsalo tak, že jsem to chtěl vždycky v životě někam dotáhnout a tak jsem jako hodně mladej začal s mým nej kamarádem z dětství podnikat v IT. 🧑‍💻
                </p>

                <p>
                  V 16 letech jsme měli náš první projekt, v 18 jsme spolu zakládali firmu, kterou máme do teď.
                  Práce byla můj život, pracoval jsem od nevidim do nevidim. 🤯
                  Ve 26 letech jsem vyhořel. 🔥 Trefil jsem dno svých sil. Připadalo mi, že jsou lidi v mé firmě nesamostatní a nedokázal jsem na ně práci delegovat. Nevěděl jsem jak se z toho vymotat... 🤷‍♂️
                </p>
                
                <p>
                  Jeden večer mě Jolanda motivovala svým "ale ze sraček můžete vytáhnout se". Otevřel jsem si mapu a začal zapichovat prsty do měst po Evropě - stylem "Hmm, Berlín, super, hl. město Německa, tam se chci podívat!".
                  Vybral jsem tímhle prdlým způsobem pár hlavních měst. Za týden jsem seděl ve vlaku do Berlína.
                  Evropa mi vydržela asi měsíc. Do teď si pamatuju, jak jsem v Paříži přemýšlel jestli se vrátím nebo pojedu dál. Nakonec jsem našel za dobrou cenu letenky do Thajska a odletěl do Asie. Byla to moje první cesta letadlem! ✈️🤣
                  Asie mě hrozně bavila a tak jsem po Thajsku pokračoval dál na Bali, do Singapuru, do Kuala Lumpur, na Sri Lanku a nakonec do Vietnamu, kde jsem si koupil motorku a projel Vietnam od jihu na sever.
                </p>

                <div class="video-container video-jolanda" id="vid-RO4dS_LlGZs">
                  <iframe width="560" height="315" id="player-RO4dS_LlGZs" src="https://www.youtube.com/embed/RO4dS_LlGZs?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <p>
                  Když jsem se po roce vrátil, byl jsem úplně někdo jiný. Měl jsem spoustu zážitků, daleko větší rozhled a jiný pohled na svět.
                  Bohužel, tahle zkušenost se špatně předává dál a když jsem se bavil s kamarády, najednou jsme si neměli moc co říct.
                  Přišlo mi jako by vůbec nežili - a já poslouchal ty samé pohádky, stížnosti na život a výmluvy proč to nejde jinak. Jako bych byl pryč týden.
                  Prostě se v jejich životě za ten rok vůbec nic nezměnilo. 😏
                </p>

                <p>
                  Od té doby se snažím řídit kýčem 😅 "Kdo chce - hledá způsoby, kdo nechce - hledá důvody."
                  Moje firma funguje daleko lépe než dřív, umím delegovat práci na kolegy a pomalu rosteme.
                  Už to v životě nechci "někam dotáhnout".
                  V životě dělám jen to co mě baví a někam posouvá.
                </p>

                <p>
                  Stal se ze mě pohodář, digitální nomád, trošku blázen, ale hlavně chlap co nezkazí žádnou srandu a nevydrží se jen tak válet doma.
                  Chlap co poznává svět, chodí po horách, hraje badminton, padel tenis a chodí pravidelně cvičit. 💪
                  Mám strach z výšek a tak se ho zbavuju tím, že se učím lézt na umělý stěně a trošku feratuju.
                  Rád spím venku v přírodě jen tak pod hvězdama ✨, ale nevyhýbám se ani hotelům a když cestuju sám, často spím v hostelu, abych poznával nové lidi.
                  Hraju divadelní improvizaci 🎭 a tak mě někdy můžeš vidět blbnout na pódiu.
                </p>

                <p>
                  ... a jmenuju se Vašek a jsem nenormální binární chlap 32/186/78. 😇 
                </p>

                <!--<img src="../assets/ja/1.jpg" />-->
                <!--<img src="../assets/ja/3.jpg" />-->
                <img src="../assets/ja/11.jpg" />
                <img src="../assets/ja/10.jpg" />
                <img src="../assets/ja/4.jpg" />
                <img src="../assets/ja/5.jpg" />
                <!--
                <img src="../assets/ja/8.jpg" />
                <img src="../assets/ja/6.jpg" />-->
                <!--<img src="../assets/ja/7.jpg" />-->
                <!--<img src="../assets/ja/2.jpg" />-->
            </div>
            <div class="paging paging-right">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>
    
    <div class="page" >
        <div class="page-content">
            <div class="page-header">
                <h2>Jdeš do toho?</h2>
            </div>
            <div class="page-text">
                <p>
                  Uff, díky, za dočtení až sem! Teď to nejtěžší rozhodnutí na závěr! 😅 Zaujalo tě to, ozveš se? Je to na tobě.
                </p>

                <p>
                  <a href="https://www.facebook.com/profile.php?id=100092144064226" target="_blank">Tady je můj FB profil, kde si můžeme napsat.</a>
                  Pokud facebooku neholduješ, můj telefon je 722 234 149 neboj se napsat, koušu jenom trochu! 😇
                </p>

                <p> 
                 Rád se s tebou sejdu někdě na kafe, čaj nebo pivíno, kde můžem probrat zážitky z cest nebo zkušenosti z diginomádění nebo cokoliv jinýho! 🥳
                </p>

                <p>
                  Ještě jednou díky za Tvůj čas!<br />
                  Měj se krásně a uživej života a nezapomeň co říkala Jolanda, i ty „Hodně budeš někde“ 😅<br />
                  Pa, Vašek
                </p>
            </div>
            <div class="paging paging-left">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
              <img src="../assets/redo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="nextPage" />
            </div>
        </div>
    </div>

    <div class="page" >
        <div class="page-content">
            <div class="page-text">
                <img src="../assets/citat.jpeg" />
            </div>
            <div class="paging">
              <img src="../assets/undo.svg" style="height: 40px; width: 40px; margin-top: 20px;" @click="prevPage" v-if="mobile" />
            </div>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
import { PageFlip } from '../page-flip.module'

export default {
  data() {
    return {
      page: 0,
      mobile: false,
    }
  },
  methods: {
    initPageFlip() {
      if (this.$refs.book) {
        if (this.$refs.book.parentNode.clientWidth < 600) {
          this.$refs.book.style.width = this.$refs.book.parentNode.clientWidth - 20 + 'px'
          const safeAreaTopBottom = this.$refs.book.parentNode.clientHeight - window.innerHeight
          //this.$refs.book.style.height = this.$refs.book.parentNode.clientHeight - safeAreaTopBottom - 20 + 'px'
          this.$refs.book.firstChild.firstChild.style.height = this.$refs.book.parentNode.clientHeight - safeAreaTopBottom - 20 + 'px'
          this.mobile = true
          return
        }


        const settings = {
          //width: 680,
          //height: 657,
          size: "fixed",
          /*
          minWidth: 315,
          maxWidth: 1e3,
          minHeight: 400,
          maxHeight: 1533,
          */
          maxShadowOpacity: .5,
          showCover: !0,
          //mobileScrollSupport: !1
          //useMouseEvents: false
        }
        
        if (this.$refs.book.parentNode.clientWidth < 600) {
          const safeAreaTopBottom = this.$refs.book.parentNode.clientHeight - window.innerHeight
          settings.width =  this.$refs.book.parentNode.clientWidth - 20
          settings.minWidth =  this.$refs.book.parentNode.clientWidth - 20
          settings.maxWidth =  this.$refs.book.parentNode.clientWidth - 20
          settings.height = this.$refs.book.parentNode.clientHeight - safeAreaTopBottom - 20
          settings.minHeight = this.$refs.book.parentNode.clientHeight - safeAreaTopBottom - 20
          settings.maxHeight = this.$refs.book.parentNode.clientHeight - safeAreaTopBottom - 20
          settings.size = 'stretch'
          settings.mobileScrollSupport = true
          settings.disableFlipByClick = true
          this.mobile = true
        } else {
          settings.width =  ((this.$refs.book.parentNode.clientWidth - 80) / 2)
          settings.height = this.$refs.book.parentNode.clientHeight - 80
          settings.size = 'fixed'
          settings.mobileScrollSupport = false
          settings.disableFlipByClick = false
        }

        
        this.pageFlip = new PageFlip(this.$refs.book.firstChild, settings)
        this.pageFlip.loadFromHTML(this.$refs.book.querySelectorAll('.page'))
        this.pageFlip.on('flip', (e) => {
            this.page = e.data

            // eslint-disable-next-line no-undef
            /*
            gtag('event', 'screen_view', {
              'screen_name': 'Strana ' + e.data
            });
            */
        })
      }
    },
    nextPage(e) {
      if (this.mobile && this.page === 0) {
        e.preventDefault()
        e.stopPropagation()
        this.pageFlip.flipNext()
      }
    },
    prevPage(e) {
      if (this.mobile) {
        e.preventDefault()
        e.stopPropagation()
        this.pageFlip.flipPrev()
      }
    }
  },
  mounted() {
    console.log('here 0')
    setTimeout(() => {
      this.initPageFlip()

      // 2. This code loads the IFrame Player API code asynchronously.
      const tag = document.createElement('script')

      tag.src = "https://www.youtube.com/iframe_api"
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      console.log('here 1')
      window.onYouTubeIframeAPIReady = () => {
        console.log('here 2')
        if (this.$refs.book.parentNode.clientWidth >= 600) {
          return
        }

        const videos = this.$refs.book.querySelectorAll('.video-jolanda')
        const players = {}
        console.log('videos', videos)
        const observer = new IntersectionObserver((entries) => {
          console.log('observed', entries)
          entries.forEach((entry) => {
            if (entry.isIntersecting === false) {
              return
            }
            
            const videoId = entry.target.id.substr(4)
            if (!players[videoId]) {
              console.log('videoId', videoId)
              players[videoId] = new window.YT.Player('player-' + videoId, {
                videoId,
                events: {
                  'onReady': (event) => {
                    console.log('video ready?', event)
                    event.target.playVideo()
                  },
                }
              })
            }
          })
        }, {
          root: document.querySelector(".content-wrapper"),
          rootMargin: "0px",
          threshold: 0,
        })

        videos.forEach((video) => observer.observe(video))
        
      }
    }, 300)
/*
    window.addEventListener("resize", () => {
      if (this.pageFlip) {
        this.pageFlip.destroy()
      }

      this.initPageFlip()
      this.pageFlip.turnToPage(this.page)
    })
*/
  },
  unmounted() {
    if (this.pageFlip) {
      this.pageFlip.destroy()
    }
  },
}
</script>


<style>
#book:not(.on-page-0) .book {
    box-shadow:0 0 20px 0 rgba(0,0,0,0.5);
    /*
    margin:0 auto;
    display:none;
    z-index:100
    */
}

.page {
    background-color: #f2e8d9;
    color:#785e3a;
    border: solid 1px #998466;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.page .page-content{
    padding: 20px;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:stretch;
}
.page .page-content .page-header{
    /*height:30px;*/
    font-size:100%;
    text-transform:uppercase;
    text-align:center;
}
.page .page-content .page-image{
    height:100%;
    background-size:contain;
    background-position:center center;
    background-repeat:no-repeat
}


@media only screen and (min-width: 601px)  {
  .page .page-content .page-text {
    overflow-y: auto;
    height: 0px;
  }
}


.page .page-content .page-text {
    flex: 1 1 auto;
    text-align:left;
    padding-top:10px;
    box-sizing:border-box;
    padding-right: 10px;
    padding-left: 10px;
}

.page .page-content .page-text p {
  margin: 10px 0;
}

.page .page-content .page-text h3 {
  text-transform:uppercase;
  text-align:center;
  margin: 20px 0;
}
.page .page-content .page-text img {
  text-align:center;
  margin: 20px auto;
}

.page .page-content .paging {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.page .page-content .paging.paging-right {
  justify-content: flex-end;
}

.page .page-content .paging img {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
}

.page .page-content .page-text .video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin: 10px 0;
}

.page .page-content .page-text .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page .page-content .page-footer{
    height:30px;
    border-top:solid 1px #f4e8d7;
    font-size:80%;
    color:#998466
}
.page.--left{
    border-right:0;
    box-shadow:inset -7px 0 30px -7px rgba(0,0,0,0.4)
}
.page.--right{
    border-left:0;
    box-shadow:inset 7px 0 30px -7px rgba(0,0,0,0.4)
}



.page.hard{
    background-color:#f2e8d9;
    border:solid 1px #998466
}

.page .page-cover {
    background-color: black;
}
.page .page-cover h1 {
    text-align:center;
    /*
    font-family: 'Harry Potter', sans-serif;
    letter-spacing: 5px;
    */
    color: #FFD700;
    font-size: 4em;

    -webkit-text-stroke: 1px black;
   text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}

.page .page-cover h2 {
    color: #FFD700;
    text-align:center;
    font-size: 1.5em;
    margin-top: 20px;

    -webkit-text-stroke: 1px black;
   text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}
.page .page-cover .page-cover-header {
  height:100%;
  flex-grow:1;
  background-image: url('../assets/bali-uvod.jpg');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
}

@media only screen and (max-width: 600px)  {
  .page {
    margin-bottom: 20px;
  }

  .page .page-content .paging {
    display: none;
  }

  .page .page-cover h1 {
    font-size: 3em;
  }
}

/*
.page.page-cover.page-cover-top{
    box-shadow:inset 0px 0 30px 0px rgba(36,10,3,0.5),-2px 0 5px 2px rgba(0,0,0,0.4)
}

.page.page-cover.page-cover-bottom{
    box-shadow:inset 0px 0 30px 0px rgba(36,10,3,0.5),10px 0 8px 0px rgba(0,0,0,0.4)
}
*/
</style>